.footer {
    min-height: 80px;
    line-height: 80px;
}

.footer p {
    margin: 0%;
    text-align: center;
    vertical-align: middle;
}
