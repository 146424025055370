.idSectionContacts h3 {
    color: #304838;
    text-align: center;
}

.name {
    text-align: center;
}

.address-map img {
    width: 100%;
    height: auto;
}

.contacts-container {
    display: flex;
}

.contacts-container-column1 {
    width: 50%;
}

.contacts-container-column2 {
    width: 50%;
}

.contacts-container .contacts-short a {
    font-size: 100%;
}

@media only screen and (max-width: 800px) {
    .contacts-container {
        display: block;
    }

    .contacts-container-column1 {
        width: auto;
    }

    .contacts-container-column2 {
        width: auto;
    }
}