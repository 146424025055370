.idSectionServices h3 {
    color: #304838;
    text-align: center;
}

.services-container {
    display: flex;
}

.services-container hr {
    margin: 0 16px;
    border: 1px solid lightgray;
}

.service {
    width: 100%;
}

@media only screen and (max-width: 800px) {
    .services-container {
        display: block;
    }

    .services-container hr {
        margin: auto;
    }
}