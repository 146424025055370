.nav {
    background-color: white;
    position: sticky;
    top: 0px;
    width: 100%;
    min-height: 80px;
    z-index: 1000;
    box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.15);
}

.nav .nav-content {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
}

.nav-logo {
    width: 270px;
}

.nav-logo:hover {
    cursor: pointer;
}

.nav-items {
    display: flex;
    flex-wrap: wrap;
}

.nav-item {
    display: flex;
    color: #333;
    float: left;
}

.nav-item:hover {
    color: black;
    cursor: pointer;
}

.nav-item a {
    display: block;
    text-align: center;
    padding: 16px;
    text-decoration: none;
    font-weight: bold;
    color: #304838;
}

.nav-item a.active {
    text-decoration: underline;
}

@media only screen and (max-width: 800px) {
    .nav .nav-content {
        padding: 0%;
        margin: 0 auto;
        flex-direction: column;
    }

    .nav-logo {
        margin-left: 0%;
    }

    .nav-items {
        padding: 0%;
        margin: 0%;
        justify-content: center;
    }

    .nav-item a {
        padding: 8px;
    }
}
