.facts {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
}

.fact {
    margin: 8px;
}

.fact-main {
    color: #304838;
    font-size: larger;
}

@media only screen and (max-width: 800px) {
}
