.contacts-short {
    display: flex;
    flex-wrap: wrap;
    /* margin-top: 16px; */
    justify-content: center;
    text-align: center;
}

.shadow {
    box-shadow: 0px 15px 10px -15px rgba(0, 0, 0, 0.15);
}

.contacts-short-text {
    width: 100%;
}

.contacts-short ul {
    list-style-type: none;
}

.contacts-short a {
    font-weight: bold;
    text-decoration: none;
    color: #304838;
    font-size: 175%;
    overflow-wrap: break-word;
}

.contacts-short svg {
    padding-right: 10px;
    vertical-align: 0%;
}

@media only screen and (max-width: 800px) {
    .contacts-short ul {
        padding-inline-start: 1px;
    }

    .contacts-short a {
        font-size: large;
    }

    .contacts-short svg {
        display: none;
    }

    .contacts-short-phone {
        width: 50%;
    }
    
    .contacts-short-write {
        width: 50%;
    }
}