.idSectionServicesCosts h3 {
    color: #304838;
    text-align: center;
}

.asterisk-comments {
    font-weight: bold;
    font-size: small;
}

.disclaimer-box {
    width: auto;
    text-align: center;
    border: 1px solid #304838;
    padding: 16px;
}

.services-costs-container {
    display: flex;
}

.services-costs-container table {
    table-layout: fixed;
    width: 100%;
}

.services-costs-container tr {
    background-color: #D8D8D8;
}

.services-costs-container tr:nth-child(even) {
    filter: brightness(110%);
}

.services-costs-container td {
    padding: 8px;
}

.services-costs-container td:nth-child(even) {
    text-align: center;
}

.services-costs-container hr {
    margin: 0 16px;
    border: 1px solid lightgray;
}

@media only screen and (max-width: 800px) {
    .services-costs-container {
        display: block;
    }

    .services-costs-container hr {
        margin: auto;
    }

    .services-costs-container td {
        padding: 4px;
    }
}