.idSectionAbout h2 {
    text-align: center;
}

.info-container {
    display: flex;
}

.info-container p {
    text-indent: 20px;
    margin-left: 2px;
    margin-right: 2px;
}

.info-1 {
    width: 50%;
}

.info-2 {
    width: 50%;
}

.info-2 h3 {
    color: #304838;
}

@media only screen and (max-width: 800px) {
    .info-container {
        flex-direction: column;
    }
    
    .info-1 {
        width: 100%;
    }
    
    .info-2 {
        width: 100%;
    }

    .info-2 h3 {
        text-align: center;
    }
}
