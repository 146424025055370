.landing-screen-container {
    width: 100%;
    /*adjust for navbar*/
    height: calc(100vh - 80px);
    min-height: 300px;
    background-image: url(../../public/landing_screen_background.jpg);
    background-position: center top;
    background-size: cover;
}

/* hatch from https://www.magicpattern.design/tools/css-backgrounds */
.landing-screen-hatch {
    width: 100%;
    height: 100%;

    background-color: rgba(0, 0, 0, 0.7);
    background: repeating-linear-gradient(45deg,
            rgba(23, 22, 22, 0.7),
            rgba(23, 22, 22, 0.7), 3.5px,
            rgba(0, 0, 0, 0.7) 3.5px,
            rgba(0, 0, 0, 0.7) 17.5px);
}

.landing-screen-content-container {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    color: white;

    font-family: 'Montserrat', sans-serif;
}

.landing-screen-content-container svg {
    width: 120px;
    height: auto;
}

.landing-screen-content-container svg g {
    fill: white !important;
}

.landing-screen-content-name-additional h2 {
    font-weight: 400;
    text-transform: uppercase;
    font-size: 20px;
}

.landing-screen-content-name-main h1 {
    font-weight: 700;
    font-size: 70px;
}

.landing-screen-content-slogan h2 {
    font-weight: 300;
    text-transform: uppercase;
    font-weight: normal;
    font-size: 17px;
}

.landing-screen-content-phone a {
    color: #50bbb9;
    text-decoration: none;
    font-weight: 700;
    font-size: 30px;
}

.arrow-container {
    margin-top: 40px;
}

.arrow-down {
    border: solid rgba(120, 118, 118, 0.9);
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 18px;
    /* rotate 45deg moved to bounce animation */
}

.bounce {
    animation: bounce 3s infinite;
}

@keyframes bounce {

    0%,
    100% {
        transform: translateY(0) rotate(45deg);
    }

    50% {
        transform: translateY(-30px) rotate(45deg);
    }
}

.landing-screen-content-footer {
    height: 80px;
}

@media only screen and (max-width: 800px) {
    .landing-screen-container {
        /*adjust for navbar*/
        height: calc(100vh - 140px);
    }

    .landing-screen-content-container svg {
        width: 80px;
        height: auto;
    }

    .landing-screen-content-name-additional h2 {
        font-size: 15px;
    }

    .landing-screen-content-name-main h1 {
        font-size: 35px;
    }

    .landing-screen-content-slogan h2 {
        font-size: 12px;
    }

    .landing-screen-content-phone a {
        font-size: 20px;
    }

    .arrow-container {
        margin-top: unset;
    }

    @keyframes bounce {

        0%,
        100% {
            transform: translateY(0) rotate(45deg);
        }

        50% {
            transform: translateY(-10px) rotate(45deg);
        }
    }

    .landing-screen-content-footer {
        height: 20px;
    }
}