.content-container {
    margin: 0 auto;
    width: 80%;
    max-width: 1024px;
}

@media only screen and (max-width: 800px) {
    .content-container {
        width: 97%;
    }
}
